const config = require("./gatsby-config")

exports.onClientEntry = () => {
    const path = window.location.pathname
    if (path === '/') {
        const userLang = navigator.language.split('-')[0]
        const lang = config.siteMetadata.languages.filter(l => l === userLang).length === 0 ?
            config.siteMetadata.defaultLanguage : userLang
        window.location.pathname = `/${lang}/`
    }
}

require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
});

module.exports = {
  siteMetadata: {
    title: `BLB vignobles`,
    description: `BLB Vignobles est la société mère qui produit les vins des Domaine de la Jasse, Domaine Montlobre et Vignes des Deux Soleils.`,
    author: `IOcean`,
    languages: [`fr`, `en`],
    defaultLanguage: `fr`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${process.env.GATSBY_IMAGES_FILEPATH}`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/blb-icon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: "gatsby-source-graphql",
      options: {
        // This type will contain remote schema Query type
        typeName: "jhipster",
        // This is field under which it's accessible
        fieldName: "jhipster",
        // Url to query from        
        url: `${process.env.GATSBY_CMS_GRAPHQL_URL}`
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        // The property ID; the tracking code won't be generated without it
        trackingId: "UA-38348769-26",
        // Defines where to place the tracking script - `true` in the head and `false` in the body
        head: true,
      }
    },
  ],
}
